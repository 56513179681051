<template>
  <b-col cols="12">
    <b-card class="px-2 py-1">
      <b-row class="flex-column">
        <p class="my-0">
          ¡Felicitaciones! Cuentas con una línea aprobada para realizar operaciones de adelanto de facturas y letras de tus clientes aceptados por EFIDE.
        </p>
        <p class="mb-0 mt-1">
          Si requieres una extensión de línea para futuras operaciones solo debes darle clic al botón de "Ampliar línea" y se iniciará el proceso.
        </p>
      </b-row>
      <b-row class="mt-1 d-flex justify-content-center">
        <b-col
          lg="6"
          class="my-1"
        >
          <div
            v-if="!loaded"
            class="d-flex justify-content-center"
          >
            <div
              class="spinner-border"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <template v-else>
            <b-list-group>
              <b-list-group-item class="d-flex justify-content-between align-items-center">
                <b class="mr-1"> Importe de la línea: </b>
                <span> {{ clientData.amount }} </span>
              </b-list-group-item>
              <b-list-group-item class="d-flex justify-content-between align-items-center">
                <b class="mr-1"> Saldo utilizado: </b>
                <span> {{ clientData.usedAmount }}  </span>
              </b-list-group-item>
              <b-list-group-item class="d-flex justify-content-between align-items-center">
                <b class="mr-1"> Saldo disponible: </b>
                <span> {{ clientData.amount - clientData.usedAmount }} </span>
              </b-list-group-item>
            </b-list-group>
            <b-row class="justify-content-center">
              <b-button
                class="mt-2"
                variant="primary"
                type="button"
                @click="onIncreaseClientLine"
              >
                Ampliar línea
              </b-button>
            </b-row>
          </template>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="px-2 py-1 mt-2">
      <h2> Mi saldo vigente por cliente </h2>
      <p> Es el saldo de las operaciones que tienes en cartera con cada uno de tus clientes y que aún no han sido cancelados. </p>
      <div
        v-if="!loaded"
        class="d-flex justify-content-center"
      >
        <div
          class="spinner-border"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <template v-else>
        <div
          v-if="payers.length===0"
          class="d-flex justify-content-center col-12 mt-3 mb-2"
        >
          <h3>
            Todavía no ha registrado ninguna pagadora para operar
          </h3>
        </div>
        <vue-good-table
          v-else
          :columns="columns"
          :rows="payers"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
          class="mt-3 mb-2"
        >
          <div
            slot="emptystate"
            class="text-center my-5"
          >
            <h3
              v-if="payers.length===0"
            >
              Todavía no ha registrado ninguna pagadora para operar
            </h3>
          </div>
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field === 'SaldoVigente'">
              <span> S/ {{ props.row.SaldoVigente }} </span>
            </span>
          </template>
          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-center flex-wrap mt-1">
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </template>
        </vue-good-table>
      </template>
    </b-card>
  </b-col>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VueGoodTable,
  },
  data() {
    return {
      clientId: '',
      payers: [],
      columns: [
        {
          label: 'RUC',
          field: 'RUCPagadora',
        },
        {
          label: 'Razón Social',
          field: 'RazonSocialPagadora',
        },
        {
          label: 'Detalle de saldo utilizado',
          field: 'SaldoVigente',
          type: 'number',
          // formatFn: this.getAmount,
        },
      ],
      clientData: {
        amount: 0,
        usedAmount: 0,
      },
      pageLength: 5,
      loaded: false,
    }
  },
  async created() {
    this.getClientLine()
  },
  methods: {
    async getClientLine() {
      try {
        const { efideData } = JSON.parse(localStorage.getItem('userData'))
        this.clientId = efideData ? efideData.clientId : ''
        const { data: { clientLine } } = await useJwt.getClientLine({
          user: 'UsuarioPD',
          clientId: this.clientId,
        })

        // console.log(clientLine)

        if (clientLine.length === 0) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'No cuenta con línea',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: 'No cuenta con línea habilitada',
            },
          },
          {
            timeout: 8000,
          })

          this.loaded = true
          return
        }

        const [myline] = clientLine

        this.clientData.amount = myline.ImporteLinea
        this.clientData.usedAmount = myline.ImporteUtilizado

        this.payers = myline.LstClientePagadora ? myline.LstClientePagadora : []
        this.loaded = true
      } catch (error) {
        console.log(error)
      }
    },
    getAmount(columnData) {
      return `S/ ${(columnData)}`
    },
    async onIncreaseClientLine() {
      try {
        const { data: { clientLine } } = await useJwt.requestIncreaseClientLine({
          user: 'UsuarioPD',
          clientId: this.clientId,
        })

        if (clientLine[0].Status === 'OK') {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Éxito',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: 'En breve un ejecutivo se contactará con Ud. para continuar con la ampliación de línea.',
            },
          },
          {
            timeout: 8000,
          })
        }
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
